import { saveAs } from 'file-saver';
var JSZip = require("jszip");
var JSZipUtils = require("jszip-utils");


/**
 * Reset the message.
 */
function resetMessage () {
    $("#result")
        .removeClass()
        .text("");
}
/**
 * show a successful message.
 * @param {String} text the text to show.
 */
// eslint-disable-next-line no-unused-vars
function showMessage(text) {
    resetMessage();
    $("#result")
        .addClass("alert alert-success")
        .text(text);
}
/**
 * show an error message.
 * @param {String} text the text to show.
 */
function showError(text) {
    resetMessage();
    $("#result")
        .addClass("alert alert-danger")
        .text(text);
}
if(!JSZip.support.blob) {
    showError("This demo works only with a recent browser !");
}

/**
 * Fetch the content and return the associated promise.
 * @param {String} url the url of the content to fetch.
 * @return {Promise} the promise containing the data.
 */
function urlToPromise(url) {
    return new Promise(function(resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
            if(err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
}

$("#download_form").on("submit", function () {
    resetMessage();

    var zip = new JSZip();

    // find every checked item
    if ($(this).find(":checked").length > 0) {
     
        $(this).find(":checked").each(function () {
            var $this = $(this);
            var url = $this.data("url");
            var filename = url.replace(/.*\//g, "");
            zip.file(filename, urlToPromise(url), {binary:true});
        });

        // when everything has been downloaded, we can trigger the dl
        zip.generateAsync({type:"blob"}, function updateCallback(metadata) {
            var msg = "progression : " + metadata.percent.toFixed(2) + " %";
            if(metadata.currentFile) {
                msg += ", current file = " + metadata.currentFile;
            }
        }).then(function callback(blob) {
            // see FileSaver.js
            saveAs(blob, "veo-downloads.zip");
        }, function (e) {
            showError(e);
        });
    } else {
        showError("Bitte wählen sie eine oder mehrere datein aus")
    }
    return false;
});
$("#download_all").on("click", function () {
    resetMessage();

    var zip = new JSZip();

    // find every checked item
    console.log($('#download_form').find(".download-item"))
    if ($('#download_form').find(".download-item").length > 0) {
     
        $('#download_form').find(".download-item").each(function () {
            var $this = $(this);
            var url = $this.data("url");
            var filename = url.replace(/.*\//g, "");
            zip.file(filename, urlToPromise(url), {binary:true});
        });

        // when everything has been downloaded, we can trigger the dl
        zip.generateAsync({type:"blob"}, function updateCallback(metadata) {
            var msg = "progression : " + metadata.percent.toFixed(2) + " %";
            if(metadata.currentFile) {
                msg += ", current file = " + metadata.currentFile;
            }
        }).then(function callback(blob) {
            // see FileSaver.js
            saveAs(blob, "veo-downloads.zip");
        }, function (e) {
            showError(e);
        });
    } else {
        showError("Bitte wählen sie eine oder mehrere dateien aus")
    }
    return false;
});